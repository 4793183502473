.PopOver {
	position: absolute;
	background-color: white;
	color: var(--primary-color);
	margin: 0;
	padding: .5em 0;
	border-radius: 1em;
	overflow: hidden;

	list-style: none;
	box-shadow: 10px 10px 0 rgba(0,0,0, .3);
}

.PopOver li {
	padding: .3em 1em;
	cursor: pointer;
}

.PopOver li:not(:last-child) {
	border-bottom: 1px solid #5822c380;
}