.Navbar {
  padding: 0.9em 1.2em;
  color: white;
}

.Navbar > *:not(:last-child) {
  margin-right: 1em;
}

.Navbar header > *:not(:last-child) {
  margin-right: 1em;
}

.User > *:not(:last-child) {
  margin-right: 1em;
}

.Avatar img {
  border: 2px solid white;
}
