.Dashboard {
	height: 100%;
}

.Dashboard .Rooms {
	margin-bottom: 50px;
}

.Dashboard .Rooms-Grid .Room-Wrapper {
	filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.3));
}

.Dashboard .Rooms-Grid .Room {
	width: 145px;
	height: 138px;

	margin: 22px 24px;

	border: 2px solid white;
	border-radius: 10px;
	background-color: var(--primary-color);
	color: white;
	text-decoration: none;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	text-align: center;

	overflow: hidden;
}

.Dashboard .Rooms-Grid .Room.Create {
	width: 338px;
}

.Dashboard .Rooms-Grid .Room h1 {
	font-size: 1.5em;
}

.Dashboard .Rooms-Grid .Room a {
	text-decoration: none;	
}
.Dashboard .Rooms-Grid .Room a:hover {
	text-decoration: underline;	
}

.Dashboard .Rooms-Grid .Room .join {
	background-color: white;
	color: var(--primary-color);
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	font-style: normal;
	padding: 0.5em 0;
}
.Dashboard .Rooms-Grid .Room:hover .join {
	text-decoration: underline;
}

.Dashboard .Rooms-List {
	padding: calc(25px + 1em) calc(10px + 15vw);	
}

.Dashboard .Rooms-List .Room-Wrapper {

}

.Dashboard .Rooms-List .Room {
	
}

.Dashboard .Rooms-List .Room.Create {
	margin-bottom: 1em;
}

.Dashboard .Rooms-List h1 {
	font-size: 1.3em;
	margin: 0;
}

.Dashboard .Rooms-List a.Room {
	text-decoration: none;
}

.Dashboard .Rooms-List .Circle {
	width: 2em;
	height: 2em;
	border-radius: 50%;
}

.Dashboard .Rooms-List .Room .join {
	background-color: white;
	color: var(--primary-color);
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	font-style: normal;
	padding: 0.3em 1em;
	border-radius: 4px;
}
.Dashboard .Rooms-List .Room:hover .join {
	text-decoration: underline;
}


.Dashboard .vibrant {
	background: conic-gradient(from 0deg at 50% -1%, #C0398D 0deg, #FF8C1A 360deg);	
}