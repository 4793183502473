.Admin {
	height: 100%;
	font-family: Montserrat, sans-serif;
	font-style: normal;
	padding: calc(50px + 2em);
}

.Admin .list a {
	text-decoration: none;
	padding: .2em .5em;	
}

.Admin .list a.active {
	font-weight: bold;
	letter-spacing: 0.02em;
	background-color: rgba(255,255,255, .2);
}

.Admin .list a:hover {
	background-color: rgba(255,255,255, .3);	
}

.Admin .hover-highlight:hover {
	background-color: rgba(255,255,255, .2);	
}

.Admin .Side {
	flex: 1;
	padding: 0 1em;
	border-right: 1px solid rgba(255,255,255,.4);	
}

.Admin .Main {
	flex: 5;
}

.Admin .Rooms {
	flex: 1;
	padding: 0 1em;
	border-right: 1px solid rgba(255,255,255,.4);	
}

.Admin .Room {
	flex: 4;
	padding-left: 2em;
}

.Admin .Room a {
	text-decoration: none;
}

.Admin .Room a:hover {
	text-decoration: underline;
}

.Admin .Users {
	flex: 2;
	padding: 0 .5em;
	border-right: 1px solid rgba(255,255,255,.4);	
}

.Admin .User {
	flex: 3;
	padding: 0 .5em;
}

.Admin .Save {
	width: 51px;
}

.Admin span.small {
	font-size: 0.7em;
}