:root {
    --primary-color: #5822c3;
    --highlight-color: #ff8c1a;
}

* {
    box-sizing: border-box;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    background-color: black;
    color: white;
    font-family: LobsterTwo, serif;
    font-style: italic;
}

#root {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
}

.col {
    display: flex;
    flex-direction: column;
}

.center {
    justify-content: center;
}

.start {
    align-items: flex-start;
}

.middle {
    align-items: center;
}

.end {
    align-items: flex-end;
}

.grow {
    flex-grow: 1;
}

.stretch {
    align-items: stretch;
}

.wrap {
    flex-wrap: wrap;
}

.align-self-start {
    align-self: flex-start;
}

.padding-horizontal {
    padding-left: 1em;
    padding-right: 1em;
}

.round {
    border-radius: 50%;
}

#meet-container {
    height: 100%;
    flex-grow: 1;
}

.App {
    width: 100vw;
    background-color: var(--primary-color);
}

a {
    color: white;
}

button,
a.button {
    height: 50px;
    appearance: button;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 0.85em; 
    font-weight: 500;
    font-style: normal;
    cursor: pointer;
    transition: background-color 0.2s;
    text-decoration: none;
    line-height: 44px;
    padding: 0 6px;
}

button:hover,
a.button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

button:disabled,
button[disabled] {
    opacity: .4;
    cursor: not-allowed;
}

button:disabled:hover,
button[disabled]:hover {
    background-color: transparent;
}

button.active {
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: bold;
}

button.small {
    font-size: 13px;
    padding: 2px 6px;
    height: initial;
    line-height: initial;
    border-width: 1px;
}

button.icon {
    border: none;
    padding: 2px;
}

label {
    display: flex;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-size: 10px;
    align-items: stretch;
    text-align: left;
    margin: 0.5em 0;
    color: rgba(255, 255, 255, 0.7);
}

label input {
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 2px solid white;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    padding: 0.25em 0;
}

label input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
}

label input:focus-visible {
    outline: none;
    box-shadow: inset 0px -2px 0px var(--highlight-color);
    /* border-bottom: 2px solid white, 1px solid #FF8C1A; */
}

label > * {
    font-size: initial;
    color: white;
}

label > *:not(:first-child) {
    margin-top: 4px;
}

.list-item {
    background-color: rgba(255,255,255, 0.2);
    border-radius: 6px;
    padding: 3px 6px;
    transition: background-color .1s;
}

.list-item:hover {
    background-color: rgba(255,255,255, 0.3);
    border-radius: 6px;
    padding: 3px 6px;
}

.list-item:not(:last-child) {
    margin-bottom: 6px;
}

.warning {
    font-style: italic;
    color: var(--highlight-color);
}

.Loader {
    width: 250px;
    height: 250px;
    mask: url(assets-inline/loading-mask.svg);
    background-image: conic-gradient(
        from 180deg at 50% 50%,
        rgba(255, 255, 255, 0) 0deg,
        rgba(255, 255, 255, 0) 55deg,
        #ffffff 360deg
    );
    animation: 5s linear infinite spin;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
